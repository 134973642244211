<template>
  <div class="about">
    <Block
      title="Airport Transfers"
      content="Matrix Limos airport services are designed for high-quality, luxury and value. Our chauffeured car from Airport to your hotel and vice-versa offers unparalleled personalized transportation services. We are always on heels to meet your unique corporate or personal traveling needs, especially for short notice travels. Our fleet of Suburban SUV, Highlander, Limo, Sedan and other VIP luxury cars is always ready to serve you. With our quick and responsive customer service, you can instantly book a luxurious car of your choice from or to the airport. No more delays in reaching the airport or reaching your hotel for an important business meeting; our service arrives well-before your time of arrival or departure. Rely on us for your airport transportation needs and competitive pricing!"
      imgURL="airport_transfer-1.jpeg"
      orderChange
    />
    <Block
      title="Cinemas"
      content="Have a plan to enjoy your night at the movies with friends or family or with your beloved? Let MatrixLimos know about your plan, and we assure to make it much more fun and entertaining with our luxury vehicle booking service. Impressively reach your desired cinema in our chauffeur-driven vehicles. Book the best service in the locality at the most affordable hourly rates. We promise to make your every trip comfortable, luxury and out of this world!"
      imgURL="cinamas.jpg"
    />
    <Block
      title="Night Parties"
      content="Your night parties can be more fun and entertaining if you arrive at the party premises in an impressive vehicle. At Matrix Limos, we offer VIP transportation services to help you make a dramatic appearance at the party and enjoy your ride. All our vehicles arrive at your premises with a neatly uniformed chauffeur who gives you a feel of royalty. Enjoy your trip to the party destination at any time of night with your friends or your beloved like a king or queen. Book in advance at the most competitive price to save yourself the hassle of finding the fabulous limo booking at the time of the party!"
      imgURL="night-parties.jpg"
      orderChange
    />
    <Block
      title="Hotels"
      content="Traveling to and from a hotel can become difficult when you are in another city for business or travel. Finding a taxi or rental car can be a hassle. MatrixLimos has made it quick, simple and convenient for you to pre-book a VIP car to and from a hotel. We offer competitive pricing to provide you with the best service without being heavy on your pocket. No matter what time of the day or night it is, we are available 24/7 and 365 days a year to fulfill your luxury transportation needs."
      imgURL="hotels.jpg"
    />
    <Block
      title="Shopping Malls"
      content="Shopping is always fun, and it can be more fun if you reach your shopping destination stylishly and grandly. It makes heads turn, making you feel like a celebrity. To enjoy these feelings, MatrixLimos provide you with luxury transportation booking services. Enjoy your shopping while having a majestic VIP experience of your life. Our city limo service ensures that you have the best travel experience in our chauffeur driven, highly-maintained vehicles at the most reasonable prices."
      imgURL="shopping.jpg"
      orderChange
    />
    <Block
      title="Wedding"
      content="Your wedding is your big day which needs special treatment. From dressing to décor, venue, and transport, everything should exceed your expectations, and this is what we do. At Matrix Limos, we exceed your expectations for your wedding car booking requirements. We are masters of making your wedding ceremony entrance exciting and grand. Avail our stretch limo service with a chauffeur to give an effect of royalty to your wedding. Booking is quick and easy. Just get in touch with your requirements and our VIP vehicle will be on the spot for your wedding event, that too on the budget!"
      imgURL="weddings.jpg"
    />
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Block from "@/components/Block.vue";

export default {
  name: "Services",
  components: {
    Block,
  },
};
</script>
